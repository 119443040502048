import algoliasearch from 'algoliasearch'
import equal from 'fast-deep-equal'
import { SearchState, SearchStateRangeValue } from './Serp.types'
import { initialSearchState, serpFiltersSettings } from './SerpConstants'

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY as string
)

export const replaceBodyTypeGroup = (items: string[]) => {
  let newItems = [...items]
  for (let i = 0; i < newItems.length; i++) {
    for (const bodyType of serpFiltersSettings.bodyTypes) {
      if (bodyType.ids.includes(newItems[i])) {
        const index = newItems.indexOf(bodyType.ids[0])
        newItems = newItems.filter(newItem => !bodyType.ids.includes(newItem))
        newItems.splice(index, 0, bodyType.name)
        break
      }
    }
  }
  return newItems
}

export const serpFilterHasChanged = <T extends keyof SearchState>(
  category: T,
  attribute: keyof SearchState[T],
  currentValue: unknown
) => {
  return !equal(currentValue, initialSearchState[category][attribute])
}

export const checkSerpRangeValues = (
  { min, max }: SearchStateRangeValue,
  {
    minAllowed,
    maxAllowed
  }: {
    minAllowed: number
    maxAllowed: number
  }
) => {
  return {
    checkedValues: {
      min: min < minAllowed ? minAllowed : min,
      max: max > maxAllowed ? maxAllowed : max
    }
  }
}
