import { LINKS } from 'constants/links'
import {
  addSearchParams,
  withSearchParams
} from 'driverama-core/utils/searchParams'
import Link from 'next/link'
import { PropsWithChildren } from 'react'
import { BasicDoc, Hit, WrappedInsightsClient } from 'react-instantsearch-core'
import { connectHitInsights } from 'react-instantsearch-dom'
import algoliaAnalytics from 'search-insights'
import { AlgoliaStockCarResponse } from 'sections/serp/Serp.types'
import { logEcommerceProductClick } from 'utils/analytics/ecommerce'
import {
  CarDetailSourcePage,
  CarDetailSourceSection
} from 'utils/analytics/useLogEvents'

interface Props {
  hit: Hit<AlgoliaStockCarResponse>
  insights: WrappedInsightsClient
  source: {
    page: CarDetailSourcePage
    section: CarDetailSourceSection
  }
  scroll?: boolean
}

function AlgoliaInsightWrapperConnected({
  hit,
  insights,
  children,
  source
}: PropsWithChildren<Props>) {
  const elId = `${source.page}__${source.section}__${hit.objectID}`
  const carDetailLink = () => {
    let carDetailUrl = LINKS.carDetail({
      id: hit.stockNo,
      makeId: hit.makeId,
      modelId: hit.modelId
    })
    if (source.page === 'b2b') {
      carDetailUrl = addSearchParams(carDetailUrl, { source: 'DRIVERAMA' })
    }

    return carDetailUrl
  }

  return (
    <Link href={withSearchParams(carDetailLink())} passHref>
      <a
        id={elId}
        onClick={() => {
          insights('clickedObjectIDsAfterSearch', {
            eventName: 'Product Clicked'
          })

          logEcommerceProductClick({
            id: hit.stockNo,
            price: hit.prices?.[0]?.fullPriceEur,
            brand: hit.makeId
          })
        }}
      >
        {children}
      </a>
    </Link>
  )
}

export const AlgoliaInsightConnector = connectHitInsights(algoliaAnalytics)(
  AlgoliaInsightWrapperConnected
)

interface WrapperProps {
  hit: Hit<BasicDoc>
  source: {
    page: CarDetailSourcePage
    section: CarDetailSourceSection
  }
  scroll?: boolean
}

export function AlgoliaInsightWrapper(props: WrapperProps) {
  return <AlgoliaInsightConnector {...props} />
}
