import { exchangePowerUnits } from 'driverama-core/utils/units'
import {
  BodyTypeName,
  SearchControlsState,
  SearchInputControls,
  SearchState,
  SerpFiltersForm,
  SerpState
} from './Serp.types'

const bodyTypes: { ids: string[]; name: BodyTypeName }[] = [
  { ids: ['H'], name: 'H' },
  { ids: ['S'], name: 'S' },
  { ids: ['C'], name: 'C' },
  { ids: ['SUV'], name: 'SUV' },
  { ids: ['OFF'], name: 'OFF' },
  { ids: ['MPV', 'C-MPV', 'B'], name: 'VAN' },
  { ids: ['VAN', 'P'], name: 'BV' },
  { ids: ['CPE'], name: 'CPE' },
  { ids: ['CAB'], name: 'CAB' },
  { ids: ['TC', 'FR', 'VA', 'BV', 'TIP'], name: 'OTHER' }
]

export const serpFiltersSettings = {
  monthlyInstallmentMin: 25,
  monthlyInstallmentMax: 1000,
  monthlyInstallmentStep: 50,

  priceRangeMin: 0,
  priceRangeMax: 150000,
  priceRangeStep: 1000,

  yearMin: 1990,
  yearMax: new Date().getFullYear(),

  mileageMin: 0,
  mileageMax: 300000,

  consumptionMin: 1,
  consumptionMax: 35,

  powerKwMin: 20,
  powerKwMax: 900,

  powerHpMin: exchangePowerUnits(20, 'hp'),
  powerHpMax: exchangePowerUnits(900, 'hp'),

  cubicCapacityMin: 0,
  cubicCapacityMax: 8000,

  seatsCount: [
    { label: '2+', start: 2 },
    { label: '4+', start: 4 },
    { label: '5+', start: 5 },
    { label: '6+', start: 6 },
    { label: '7+', start: 7 },
    { label: '8+', start: 8 },
    { label: '2', start: 2, end: 2 },
    { label: '4', start: 4, end: 4 },
    { label: '5', start: 5, end: 5 },
    { label: '6', start: 6, end: 6 },
    { label: '7', start: 7, end: 7 },
    { label: '8', start: 8, end: 8 }
  ],

  doorsCount: [
    { label: '2/3', start: 2, end: 3 },
    { label: '4/5', start: 4, end: 5 }
  ],

  engineCylinders: [
    { label: '2', start: 2, end: 2 },
    { label: '3', start: 3, end: 3 },
    { label: '4', start: 4, end: 4 },
    { label: '5', start: 5, end: 5 },
    { label: '6', start: 6, end: 6 },
    { label: '8', start: 8, end: 8 },
    { label: '10', start: 10, end: 10 },
    { label: '12', start: 12, end: 12 }
  ],

  // TODO: temporary
  numberOfSeatsMin: 2,
  numberOfSeatsMax: 8,

  bodyTypes,

  configure: {
    engineCylinders: [
      {
        id: 'otherCylinders',
        query:
          'engineCylinders != 3 AND engineCylinders != 4 AND engineCylinders != 5 AND engineCylinders != 6 AND engineCylinders != 8'
      }
    ]
  }
}

export const serpFiltersDefaults: Partial<SerpFiltersForm> = {
  attributes: [],

  keyword: '',
  keywords: [],
  notify: false,

  express: false,

  modelIds: [],

  monthlyInstallmentFrom: serpFiltersSettings.monthlyInstallmentMin,
  monthlyInstallmentTo: serpFiltersSettings.monthlyInstallmentMax,

  priceRangeFrom: serpFiltersSettings.priceRangeMin,
  priceRangeTo: serpFiltersSettings.priceRangeMax,

  yearFrom: serpFiltersSettings.yearMin,
  yearTo: serpFiltersSettings.yearMax,

  mileageFrom: serpFiltersSettings.mileageMin,
  mileageTo: serpFiltersSettings.mileageMax,

  consumptionFrom: serpFiltersSettings.consumptionMin,
  consumptionTo: serpFiltersSettings.consumptionMax,

  powerKwFrom: serpFiltersSettings.powerKwMin,
  powerKwTo: serpFiltersSettings.powerKwMax,

  powerHpFrom: serpFiltersSettings.powerHpMin,
  powerHpTo: serpFiltersSettings.powerHpMax,

  cubicCapacityFrom: serpFiltersSettings.cubicCapacityMin,
  cubicCapacityTo: serpFiltersSettings.cubicCapacityMax,

  bodyIds: [],
  features: [],
  fuelTypes: [],
  transmissions: [],
  vatDeductible: false,
  seatsCountExact: false,
  metallicColor: false,
  colorMatte: false,

  // TODO: temporary
  numberOfSeatsExact: false
}

export const initialInputControls: SearchInputControls = {
  keyword: ''
}

export const initialSearchState: SearchState = {
  attributeForMyQuery: '',
  range: {
    firstRegistrationYear: {
      min: serpFiltersSettings.yearMin,
      max: serpFiltersSettings.yearMax
    },
    actualMileageKm: {
      min: serpFiltersSettings.mileageMin,
      max: serpFiltersSettings.mileageMax
    },
    'prices.fullPriceEur': {
      min: serpFiltersSettings.priceRangeMin,
      max: serpFiltersSettings.priceRangeMax
    },
    'prices.loan.monthlyInstallmentEur': {
      min: serpFiltersSettings.monthlyInstallmentMin,
      max: serpFiltersSettings.monthlyInstallmentMax
    },
    engineVolumeCcm: {
      min: serpFiltersSettings.cubicCapacityMin,
      max: serpFiltersSettings.cubicCapacityMax
    },
    enginePowerKw: {
      min: serpFiltersSettings.powerKwMin,
      max: serpFiltersSettings.powerKwMax
    },
    enginePowerPs: {
      min: serpFiltersSettings.powerHpMin,
      max: serpFiltersSettings.powerHpMax
    }
  },
  refinementList: {
    modelId: [],
    bodyId: [],
    features: [],
    fuelTypeLabels: [],
    transmissionId: [],
    colorId: [],
    source: []
  },
  menu: {
    driveId: ''
  },
  multiRange: {
    seatsCount: '',
    doorsCount: '',
    engineCylinders: ''
  },
  toggle: {
    metallicColor: false,
    vatDeductible: false
  },
  configure: {},
  sortBy: process.env.NEXT_PUBLIC_ALGOLIA_STOCK_CARS_INDEX as string
}

const initialSearchControlsState: SearchControlsState = {
  notify: false,
  seatsCountExact: false,
  powerUnits: 'kw',
  page: 1
}

export const initialSerpState: SerpState = {
  search: initialSearchState,
  controls: initialSearchControlsState
}
