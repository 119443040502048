import { paths } from 'driverama-core/api/driverama/generated/loanCalculator'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiAuthFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type LinearLoanCalculatorResponse = paths['/loan-calculator/linear-loan-calculation']['post']['responses']['200']['content']['application/com.driverama-v1+json']
export type LinearLoanRequestBody = paths['/loan-calculator/linear-loan-calculation']['post']['requestBody']['content']['application/json']

export type BalloonLoanCalculatorResponse = paths['/loan-calculator/balloon-loan-calculation']['post']['responses']['200']['content']['application/com.driverama-v1+json']
export type BalloonLoanRequestBody = paths['/loan-calculator/balloon-loan-calculation']['post']['requestBody']['content']['application/json']
type QueryOpts = UseQueryOptions<unknown, unknown, LinearLoanCalculatorResponse>

export async function fetchLinearLoanCalculation(body: LinearLoanRequestBody) {
  const res = await apiAuthFetcher<LinearLoanCalculatorResponse>(
    URLS.linearLoanCalculation,
    {
      method: HTTPMethod.POST,
      body
    }
  )

  return res.json
}

export function useLinearLoanCalculationQuery(
  body: LinearLoanRequestBody,
  options?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.linearLoanCalculation,
    async () => await fetchLinearLoanCalculation(body),
    options
  )
}

export async function fetchBalloonLoanCalculation(
  body: BalloonLoanRequestBody
) {
  const res = await apiAuthFetcher<BalloonLoanCalculatorResponse>(
    URLS.balloonLoanCalculation,
    {
      method: HTTPMethod.POST,
      body
    }
  )

  return res.json
}

export function useBalloonLoanCalculationQuery(
  body: BalloonLoanRequestBody,
  options: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.balloonLoanCalculation,
    async () => fetchBalloonLoanCalculation(body),
    options
  )
}
