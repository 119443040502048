import {
  fetchLinearLoanCalculation,
  LinearLoanCalculatorResponse,
  useLinearLoanCalculationQuery
} from 'driverama-core/api/driverama/loanCalculator/loanCalculator'
import { OrderConfirmationCalculatorRequest } from 'driverama-core/api/driverama/orders/orderConfirmation'
import { OrderResponse } from 'driverama-core/api/driverama/orders/orderDetail'
import { useOrderUpsalesQuery } from 'driverama-core/api/driverama/orders/orderUpsales'
import { CarResponse } from 'driverama-core/api/driverama/stock/stockCarDetail'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { formatEurDecimals, formatNum } from 'driverama-core/utils/formatting'
import { isNotNil } from 'driverama-core/utils/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormState, useFormContext, useWatch } from 'react-hook-form'
import { useQueryClient } from 'react-query'

const downPaymentPercentages = [0, 10, 20, 30, 40, 50, 60, 70]
const loanDurations = [12, 24, 36, 48, 60, 72, 84]

export const MIN_MONTHLY_PAYMENT = 25
export const MIN_LOAN_EUR = 512
const MAX_DOWNPAYMENT_PERCANTAGE = 0.7

export type LoanCalculatorFormValues = LinearLoanCalculatorResponse[number] & {
  loanType: 'LINEAR' | 'BALLOON'
}

type Args = {
  downPaymentPercentage: number
  loanDurationMonths: number
  fullCarPrice: number
  upsales?: LoanCalculatorFormValues['upsales']
}

function findLoanConfigByPercentage(
  data: LinearLoanCalculatorResponse,
  downPaymentPercentage: number,
  months: number
) {
  return data
    .filter(d => d.downPaymentPercentage === downPaymentPercentage)
    .find(d => d.loanDurationMonths === months)
}

function findLoanConfigByDownPayment(
  data: LinearLoanCalculatorResponse,
  downPayment: number,
  months: number
) {
  return data
    .filter(d => d.downPaymentValueEur === downPayment)
    .find(d => d.loanDurationMonths === months)
}

export function useLoanCalculatorUpsales(order?: OrderResponse) {
  const upsalesQuery = useOrderUpsalesQuery()
  const availableUpsales = useMemo(
    () => upsalesQuery.data?.map(upsale => upsale.productType) || [],
    [upsalesQuery.data]
  )

  const delivery = order?.delivery

  return useMemo(() => {
    if (availableUpsales && order?.items?.length) {
      const result = order?.items?.reduce((acc, item) => {
        if (
          availableUpsales?.includes(item.productType) &&
          item.productType !== 'CAR_REGISTRATION' // calculated on the backend, don't include it here
        ) {
          acc.push({
            name: item.productType,
            priceIncludingVatEur: item.totalPriceEur
          })
        }
        return acc
      }, [] as LoanCalculatorFormValues['upsales'])

      if (delivery) {
        result.push({
          name: 'DELIVERY',
          priceIncludingVatEur: delivery.priceEur
        })
      }

      return result
    }

    return []
  }, [availableUpsales, order?.items, delivery])
}

export function useLoanCalculator({
  downPaymentPercentage,
  loanDurationMonths,
  fullCarPrice,
  upsales = []
}: Args) {
  const { reset, setValue } = useFormContext<LoanCalculatorFormValues>()
  const queryClient = useQueryClient()

  const {
    data: loanCalculatorData = [],
    isError,
    isFetching
  } = useLinearLoanCalculationQuery(
    {
      subjectPriceIncludingVatEur: fullCarPrice,
      downPayment: {
        percentages: downPaymentPercentages
      },
      loanDurationMonths: loanDurations,
      upsales
    },
    { refetchOnWindowFocus: false }
  )

  const calculatorData = findLoanConfigByPercentage(
    loanCalculatorData,
    downPaymentPercentage,
    loanDurationMonths
  )

  const registratonFee = calculatorData?.registrationFeeEur || 0
  const downPaymentInputMax =
    (fullCarPrice + registratonFee) * MAX_DOWNPAYMENT_PERCANTAGE

  const onDownPaymentInputBlur = useCallback(
    async (downPayment: number) => {
      // this should never happen
      if (downPayment > fullCarPrice) {
        return
      }

      // if the value already exists in fetched dataset, there is no need to fetch again
      const existingDownPaymentValue = findLoanConfigByDownPayment(
        loanCalculatorData,
        downPayment,
        loanDurationMonths
      )

      if (existingDownPaymentValue) {
        setValue(
          'downPaymentPercentage',
          existingDownPaymentValue.downPaymentPercentage
        )

        return
      }

      const res = await fetchLinearLoanCalculation({
        subjectPriceIncludingVatEur: fullCarPrice,
        downPayment: {
          valueEur: downPayment
        },
        loanDurationMonths: loanDurations,
        upsales
      })

      if (isNotNil(res)) {
        // static downPayment value, so we can take first element (because its array of values for 1 specific down payment)
        setValue('downPaymentPercentage', res[0].downPaymentPercentage)

        queryClient.setQueryData(QUERY_KEYS.linearLoanCalculation, [
          // new values first
          ...res,
          ...loanCalculatorData
        ])
      }
    },
    [
      fullCarPrice,
      setValue,
      queryClient,
      loanCalculatorData,
      upsales,
      loanDurationMonths
    ]
  )

  useEffect(() => {
    const newData = findLoanConfigByPercentage(
      loanCalculatorData,
      downPaymentPercentage,
      loanDurationMonths
    )

    if (isNotNil(newData)) {
      reset({ ...newData, loanType: 'LINEAR' }, { keepDirty: true })
    }
  }, [loanCalculatorData, loanDurationMonths, downPaymentPercentage, reset])

  useEffect(() => {
    if (!upsales.length || !fullCarPrice) return

    fetchLinearLoanCalculation({
      subjectPriceIncludingVatEur: fullCarPrice,
      downPayment: {
        percentages: downPaymentPercentages
      },
      loanDurationMonths: loanDurations,
      upsales
    }).then(res => {
      if (res?.length) {
        const newData = findLoanConfigByPercentage(
          loanCalculatorData,
          downPaymentPercentage,
          loanDurationMonths
        )
        if (newData) {
          reset({ ...newData, loanType: 'LINEAR' }, { keepDirty: true })
          queryClient.setQueryData(QUERY_KEYS.linearLoanCalculation, [
            // new values first
            ...res,
            ...loanCalculatorData
          ])
        }
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsales, fullCarPrice])

  return { onDownPaymentInputBlur, downPaymentInputMax, isFetching, isError }
}

export const useLoanCalculatorFormValues = () => {
  const { control, getValues } = useFormContext<LoanCalculatorFormValues>()
  const values = getValues()

  let {
    totalLoanAmountEur,
    firstInstallmentEur,
    monthlyInstallmentEurRounded,
    loanDurationMonths,
    interestRatePercentage,
    apr,
    netLoanAmountEur,
    downPaymentPercentage,
    downPaymentValueEur,
    registrationFeeEur
  } = useWatch({ control })

  totalLoanAmountEur = totalLoanAmountEur || values.totalLoanAmountEur
  firstInstallmentEur = firstInstallmentEur || values.firstInstallmentEur
  monthlyInstallmentEurRounded =
    monthlyInstallmentEurRounded || values.monthlyInstallmentEurRounded
  loanDurationMonths = loanDurationMonths || values.loanDurationMonths
  interestRatePercentage =
    interestRatePercentage || values.interestRatePercentage
  apr = apr || values.apr
  netLoanAmountEur = netLoanAmountEur || values.netLoanAmountEur
  downPaymentPercentage = downPaymentPercentage || values.downPaymentPercentage
  downPaymentValueEur = downPaymentValueEur || values.downPaymentValueEur
  registrationFeeEur = registrationFeeEur || values.registrationFeeEur

  return {
    totalLoanAmountEur,
    firstInstallmentEur,
    monthlyInstallmentEurRounded,
    loanDurationMonths,
    interestRatePercentage,
    apr,
    netLoanAmountEur,
    downPaymentPercentage,
    downPaymentValueEur,
    registrationFeeEur
  }
}

export function getDisclaimerValues(
  values: Partial<
    Omit<
      LinearLoanCalculatorResponse[number],
      'subjectPriceIncludingVatEur' | 'upsales'
    >
  >,
  activeLang: string
) {
  return {
    apr: formatNum(values.apr, {}, activeLang),
    interestRatePercentage: formatNum(
      values.interestRatePercentage,
      {},
      activeLang
    ),
    firstInstallmentEurFormatted: formatEurDecimals(
      values.firstInstallmentEur,
      activeLang,
      2
    ),
    monthlyDuration: values.loanDurationMonths,
    subsequentMonthlyDuration: values.loanDurationMonths
      ? values.loanDurationMonths - 1
      : undefined,
    monthlyInstallmentEurFormatted: formatEurDecimals(
      values.monthlyInstallmentEurRounded,
      activeLang,
      2
    ),
    netLoanAmountFormatted: formatEurDecimals(
      values.netLoanAmountEur,
      activeLang,
      2
    ),
    totalLoanAmountEurFormatted: formatEurDecimals(
      values.totalLoanAmountEur,
      activeLang,
      2
    ),
    downPaymentEurFormatted: formatEurDecimals(
      values.downPaymentValueEur,
      activeLang,
      2
    )
  }
}

export function useFirstCalculatorInteraction(
  formState: FormState<LoanCalculatorFormValues>,
  cb: () => void
) {
  const [lock, setLock] = useState(false)

  useEffect(() => {
    if (formState.isDirty && !lock) {
      cb()
      setLock(true)
    }
  }, [formState.isDirty, lock, cb])
}

export function getCalculatorDataBE(
  data: LoanCalculatorFormValues
): OrderConfirmationCalculatorRequest {
  return {
    type: data.loanType,
    apr: data.apr,
    downPayment: data.downPaymentValueEur,
    downPaymentPercentage: data.downPaymentPercentage,
    firstInstallment: data.firstInstallmentEur,
    interestRate: data.interestRatePercentage,
    lengthMonths: data.loanDurationMonths,
    monthlyInstallment: data.monthlyInstallmentEurRounded
  }
}

export function useFinancingData(car: CarResponse, order?: OrderResponse) {
  const carItem = order?.items?.find(i => i.productType === 'CAR')
  const carPrice = carItem?.totalPriceEur || car.prices?.fullPriceEur || 0
  const upsales = useLoanCalculatorUpsales(order)
  return {
    car,
    fullCarPrice: carPrice,
    carDeliveryFee: order?.delivery?.priceEur,
    upsales
  }
}

export type FinancingData = ReturnType<typeof useFinancingData>

export const downPaymentFormatter = (value: number, locale: string) => {
  const formattedValue = formatNum(
    value,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    locale
  )

  if (isNotNil(formattedValue)) {
    return formattedValue
  }

  return value
}
