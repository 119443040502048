import { css, SerializedStyles } from '@emotion/react'
import { forwardRef, ReactNode } from 'react'
import { insetX } from 'driverama-core/styles/inset'
import { size, spaceY } from 'driverama-core/styles/spacing'
import { color, shadow } from 'driverama-core/styles/variables'
import { SUpperWrapper } from './CarCardSmall.styled'

type Props = {
  children?: ReactNode
  image?: ReactNode
  className?: string
  testId?: string
  contentStyles?: SerializedStyles
  tag?: ReactNode
}

export const CarCardSmall = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const { className, image, children, testId, tag } = props

    return (
      <div
        ref={ref}
        data-testid={testId}
        className={className}
        css={css`
          border-radius: ${size(4)};

          background: ${color('white')};
          box-shadow: ${shadow(3)};

          display: grid;
          grid-template-rows: auto 1fr;
          position: relative;
          height: 100%;
        `}
      >
        <SUpperWrapper>
          {image}

          <div
            css={css`
              height: ${size(4)};
              border-top-left-radius: ${size(4)};
              border-top-right-radius: ${size(4)};

              position: absolute;
              bottom: 0;

              background: ${color('white')};
              ${insetX(0)}

              z-index: 3;
            `}
          />
        </SUpperWrapper>
        {tag}
        <div
          css={css`
            background: ${color('white')};
            border-radius: ${size(4)};
            padding: ${size(4)};
            padding-top: 0;

            width: 0;
            min-width: 100%;
            ${spaceY(1)};

            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            transform: translateY(-1px);

            ${props.contentStyles};
          `}
        >
          {children}
        </div>
      </div>
    )
  }
)
