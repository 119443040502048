import { CarResponse } from 'driverama-core/api/driverama/stock/stockCarDetail'
import {
  ArrayElementType,
  Maybe,
  safelyAssertUnreachable
} from 'driverama-core/utils/types'
import { TFunction, useTranslation } from 'react-i18next'

export type MarketingHighlight = ArrayElementType<
  NonNullable<CarResponse['marketingHighlights']>
>

const getMarketingHighlightLabel = (
  highlight: MarketingHighlight,
  t: TFunction<'core'[]>
) => {
  switch (highlight) {
    case 'AUTOMATIC_TRANSMISSION':
      return t('core:marketing_highlight_automatic_transmission')
    case 'CNG_FUEL_TYPE':
      return t('core:marketing_highlight_cng_fuel_type')
    case 'DIESEL_FUEL_TYPE':
      return t('core:marketing_highlight_diesel_fuel_type')
    case 'ELECTRIC_FUEL_TYPE':
      return t('core:marketing_highlight_electric_fuel_type')
    case 'FACTORY_WARRANTY':
      return t('core:marketing_highlight_factory_warranty')
    case 'FOUR_WD':
      return t('core:marketing_highlight_four_wd')
    case 'FWD':
      return t('core:marketing_highlight_fwd')
    case 'HYBRID_FUEL_TYPE':
      return t('core:marketing_highlight_hybrid_fuel_type')
    case 'LPG_FUEL_TYPE':
      return t('core:marketing_highlight_lpg_fuel_type')
    case 'PETROL_FUEL_TYPE':
      return t('core:marketing_highlight_petrol_fuel_type')
    case 'RWD':
      return t('core:marketing_highlight_rwd')
    case 'SEVEN_SEATS':
      return t('core:marketing_highlight_seven_seats')
    case 'EIGHT_SEATS':
      return t('core:marketing_highlight_eigth_seats')
    case 'NINE_SEATS':
      return t('core:marketing_highlight_nine_seats')
    case 'SERVICE_BOOK':
      return t('core:marketing_highlight_service_book')
    case 'VERY_LOW_MILEAGE':
      return t('core:marketing_highlight_very_low_mileage')
    case 'LOW_MILEAGE':
      return t('core:marketing_highlight_low_mileage')
    case 'TWO_YEARS_WARRANTY':
      return t('core:marketing_highlight_two_years_warranty')
    default:
      return safelyAssertUnreachable(highlight)
  }
}

export function useMarketingHighlights() {
  const { t } = useTranslation(['core'])

  return (marketingHighlights: Maybe<MarketingHighlight[]>) =>
    marketingHighlights?.reduce<string[]>((arr, item) => {
      const label = getMarketingHighlightLabel(item, t)
      if (label) {
        arr.push(label)
      }
      return arr
    }, [])
}

export const useMarketingHighlightList = (
  marketingHighlights: Maybe<MarketingHighlight[]>
) => {
  const getMarketingHighlights = useMarketingHighlights()
  return getMarketingHighlights(marketingHighlights)
}
