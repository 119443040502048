export interface LovData {
  id: string
  name?: string
}

interface CarNameArgs {
  make?: LovData
  model?: LovData
  modelLine?: LovData
  engine?: LovData
}

const ignoredModelIds = [
  'AIETRTRON',
  'AIETRONG',
  'BW11',
  'BW22',
  'BW2ACURER',
  'BW2GROUPE',
  'BW2GRURER',
  'BW33',
  'BW3GT3GT',
  'BW44',
  'BW4GROUPE',
  'BW55',
  'BW5GT5GT',
  'BW66',
  'BW6GROUPE',
  'BW6GT6GT',
  'BW77',
  'BW88',
  'BW8GROUPE',
  'BWM2M2',
  'BWM3M3',
  'BWM4M4',
  'BWM5M5',
  'BWM6M6',
  'HAEE',
  'LSRCRC',
  'MSAA',
  'MSBB',
  'MSCC',
  'MSEE',
  'MSEQA',
  'MSEQB',
  'MSEQCEQC',
  'MSEQS',
  'MSEQV',
  'MSGG',
  'MSGLAGLA',
  'MSGLBGLB',
  'MSGLCGLC',
  'MSGLCOUPE',
  'MSGLEGLE',
  'MSGLEOUPE',
  'MSGLSGLS',
  'MSMLML',
  'MSRR',
  'MSSS',
  'MSXX',
  'MIMINIMINI'
]

export function resolveCarName(args: CarNameArgs): string {
  const { make, model, modelLine, engine } = args

  // for some models, we do not want to display the model name
  // https://driverama.atlassian.net/browse/SELL-654
  if (model && ignoredModelIds.includes(model.id)) {
    return [make?.name, engine?.name, modelLine?.name]
      .filter(str => str)
      .join(' ')
  }

  return [make?.name, model?.name, engine?.name, modelLine?.name]
    .filter(str => str)
    .join(' ')
}

interface CarPhoto {
  order: number
  url: string
}

export function resolveMainCarPhoto(photos?: CarPhoto[] | null) {
  return photos?.find(photo => photo.order === 1)?.url
}
