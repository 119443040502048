import { hasDataLayer } from 'driverama-core/analytics/utils'

// NOTE: more here: https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#product-data
interface ProductData {
  id: string

  // brand	text	No	The brand associated with the product (e.g. Google).
  brand?: string

  // category	text	No	The category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
  category?: string

  // variant	text	No	The variant of the product (e.g. Black).
  variant?: string

  // price	number	No	The price of a product (e.g. 29.20).
  price?: number

  // quantity	integer	No	The quantity of a product (e.g. 2).
  quantity?: number

  // coupon	text	No	The coupon code associated with a product (e.g. SUMMER_SALE13).
  coupon?: string

  // position	integer	No	The product's position in a list or collection (e.g. 2).
  position?: number

  // NOTE: this is not in spec but is required by marketing
  currency?: string
}

/** @deprecated */
export function logEcommerceProductClick(product: ProductData) {
  if (!hasDataLayer()) {
    return
  }

  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'productClick',
    ecommerce: { currencyCode: 'EUR', click: { products: [product] } }
  })
}
