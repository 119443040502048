import { css } from '@emotion/react'
import { LinearLoanCalculatorResponse } from 'driverama-core/api/driverama/loanCalculator/loanCalculator'
import { Flex } from 'driverama-core/components/Flex'
import { useModal } from 'driverama-core/components/modal/Modal'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { useLocalisation } from 'driverama-core/utils/localisation'
import { isNotNil } from 'driverama-core/utils/types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDisclaimerValues } from '../LoanCalculator.utils'

export type DisclaimerData = Omit<
  LinearLoanCalculatorResponse[number],
  'subjectPriceIncludingVatEur' | 'upsales'
>

function DisclaimerModal(data: DisclaimerData) {
  const { t } = useTranslation(['core'])

  const {
    activeLocale: { value: activeLang }
  } = useLocalisation(t)

  return (
    <Flex variant="column">
      <TextHeader variant={['h4', 'h3']}>
        {t('core:loan_disclaimer_modal_title')}
      </TextHeader>

      <Spacer size={4} axis="vertical" />

      <TextBody size={['small', 'large']}>
        {t('core:loan_disclaimer_modal_text', {
          ...getDisclaimerValues(data, activeLang)
        })}
      </TextBody>
    </Flex>
  )
}

export function useFinancingDisclaimerModal() {
  const { t } = useTranslation(['core'])
  const [loanData, setLoanData] = useState<DisclaimerData>()

  const [modal, openModal] = useModal(
    () => isNotNil(loanData) && <DisclaimerModal {...loanData} />,
    {
      closeLabel: t('core:close'),
      wrapperStyles: css`
        width: ${size(160)};
        padding-right: ${size(13)};
      `
    }
  )

  const handleOpenModal = useCallback(
    (data: DisclaimerData) => {
      setLoanData(data)

      openModal()
    },
    [openModal]
  )

  return {
    financingDisclaimerModal: modal,
    openFinancingDisclaimerModal: handleOpenModal
  }
}
