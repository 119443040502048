import { operations } from 'driverama-core/api/driverama/generated/orders'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiAuthFetcher } from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type OrderUpsalesReponse = operations['getAllOrderableUpsales']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryOpts = UseQueryOptions<unknown, unknown, OrderUpsalesReponse>

export type OrderUpsale = OrderUpsalesReponse[number]
export const UPSALE_REGISTRATION_TYPE = 'CAR_REGISTRATION'

export function useOrderUpsalesQuery(opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.orderUpsales(),
    async () => {
      const res = await apiAuthFetcher<OrderUpsalesReponse>(URLS.orderUpsales, {
        method: HTTPMethod.GET
      })

      return res.json
    },
    opts
  )
}
