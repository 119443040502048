import styled from '@emotion/styled'
import { insetX } from 'driverama-core/styles/inset'

const SGradient = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  ${insetX(0)}

  padding-top: 15%;
  background: linear-gradient(
    to bottom,
    rgba(252, 254, 253, 1) 50%,
    rgba(252, 254, 253, 0) 100%
  );
`

// TODO: this image is only present in driverama-ecommerce
// To ensure the component is working as expected, we shall replace
// this image with an SVG component implemented in driverama-core
export const CAR_NO_IMAGE_URL_PLACEHOLDER = '/images/placeholders/noImage.png'

export function CarCardImageGradient(props: {
  carSource?: 'DRIVERAMA' | 'AURES' | 'B2B'
  image: string
}) {
  if (
    props.carSource !== 'DRIVERAMA' ||
    props.image === CAR_NO_IMAGE_URL_PLACEHOLDER
  ) {
    return null
  }

  return <SGradient />
}
